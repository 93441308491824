import React from 'react'
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

const Products = ({ data }) => {

	const introduction = data.allMarkdownRemark.nodes;
	const product_portfolio_efiles = data.product_portfolio_efiles.nodes;
	const product_portfolio_excelsync = data.product_portfolio_excelsync.nodes;
	const product_portfolio_erequest = data.product_portfolio_erequest.nodes;
	const product_portfolio_etask = data.product_portfolio_etask.nodes;
	const product_portfolio_ehelpdesk = data.product_portfolio_ehelpdesk.nodes;
	const msteam_cloud_platform = data.msteam_cloud_platform.nodes;
	const automate_work_processes_repetitive_tasks = data.automate_work_processes_repetitive_tasks.nodes;
	const manage_daily_works = data.manage_daily_works.nodes;
	const power_tools = data.power_tools.nodes;

	return (
		<Layout>
            <SEO title="eProduct Portfolio" description="Appvity eProducts for collaboration and improve productivity for enterprise. Appvity provide task management tools and helpdesk system to improve customer's satisfaction. Our apps work well in Microsoft Teams and using Microsoft technologies for development." meta={[{ name: `keywords`, content: ["eProducts", "collaboration", "task management", "issue management", "helpdesk system"] }]} pathname="/products" />
			<div className="main-content-padding">
				<div className="app-general-seciton app-productlanding-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col>
								<div className="app-productlanding-introduction-detail">
									{introduction.map((res) => (
										<div dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
									<div className="app-productlanding-introduction-contain-img">
										<img className="app-productlanding-introduction-img" src="/app-productlanding-introduction.jpg" alt="product landing introduction" />
										<img className="app-productlanding-introduction-img-mobile-tablet" src="/app-productlanding-introduction-mobile-tablet.jpg" alt="product landing introduction" />
									</div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
					<div className="app-productlanding-spacing"></div>
                </div>
				<div className="app-general-seciton app-product-portfolio" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Product Portfolio</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-product-portfolio-detail">
							<Row className="app-row-top-align-center">
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_efiles.map((res) => (
										<div className="app-product-portfolio-detail-content-efiles" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_excelsync.map((res) => (
										<div className="app-product-portfolio-detail-content-excelsync" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4}>
									{product_portfolio_erequest.map((res) => (
										<div className="app-product-portfolio-detail-content-erequest" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
							<Row className="app-row-bottom-align-center">
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_etask.map((res) => (
										<div className="app-product-portfolio-detail-content-etask" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4}>
									{product_portfolio_ehelpdesk.map((res) => (
										<div className="app-product-portfolio-detail-content-ehelpdesk" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="app-general-seciton app-appvity-extension-architect" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Appvity eProducts Architecture</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-appvity-extension-architect">
							<img src="/app-appvity-extension-architect.svg" alt="appvity extension architect img" />
							<img className="app-appvity-extension-architect-mobile-tablet" src="/app-appvity-extension-architect-mobile-tablet.svg" alt="appvity extension architect mobile tablet img" />
						</div>
					</Container>
				</div>
				<div className="app-general-seciton app-productlanding-msteam-cloud-platform" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{msteam_cloud_platform.map((res) => (
							<div className="app-productlanding-msteam-cloud-platform-content-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-msteam-cloud-platform-content-right"></div>
				</div>
				<div className="app-general-seciton app-productlanding-automate-work-processes-repetitive-tasks" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{automate_work_processes_repetitive_tasks.map((res) => (
							<div className="app-productlanding-automate-work-processes-repetitive-tasks-content-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-automate-work-processes-repetitive-tasks-content-left"></div>
				</div>
				<div className="app-general-seciton app-productlanding-manage-daily-works" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{manage_daily_works.map((res) => (
							<div className="app-productlanding-manage-daily-works-content-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-manage-daily-works-content-right"></div>
				</div>
				<div className="app-general-seciton app-power-tool" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-power-tool-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
									{power_tools.map((res) => (
										<div className="app-power-tool-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className="app-power-tool-detail-right">
										<Row>
											<Col>
												<img src="/app-power-tool-left.jpg" alt="app power tool left" />
											</Col>
											<Col>
												<img src="/app-power-tool-right.jpg" alt="app power tool right" />
											</Col>
										</Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
			</div>
		</Layout>
	)
}

export default Products

export const query = graphql`
    query ProductLandingPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		product_portfolio_efiles: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_product_portfolio_efiles" } } }
        ) {
            nodes {
                html
                id
            }
        }
		product_portfolio_excelsync: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_product_portfolio_excelsync" } } }
        ) {
            nodes {
                html
                id
            }
        }
		product_portfolio_erequest: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_product_portfolio_erequest" } } }
        ) {
            nodes {
                html
                id
            }
        }
		product_portfolio_etask: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_product_portfolio_etask" } } }
        ) {
            nodes {
                html
                id
            }
        }
		product_portfolio_ehelpdesk: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_product_portfolio_ehelpdesk" } } }
        ) {
            nodes {
                html
                id
            }
        }
		msteam_cloud_platform: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_msteam_cloud_platform" } } }
        ) {
            nodes {
                html
                id
            }
        }
		automate_work_processes_repetitive_tasks: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_automate_work_processes_repetitive_tasks" } } }
        ) {
            nodes {
                html
                id
            }
        }
		manage_daily_works: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_manage_daily_works" } } }
        ) {
            nodes {
                html
                id
            }
        }
		power_tools: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "product_landing_power_tools" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;
